import axios from "axios";
import httpRequest from '../../services/httpRequestShipment';
const API_URL = process.env.REACT_APP_API_ENDPOINT + 'shipment/api/shipment_login_api/';


const login = (username, password) => {
  return axios
    .post(API_URL + "login", {
      username,
      password,
    }, {
      headers: {
      'Content-Type': 'application/json',
      'Content-Type': 'text/plain'
      }
    })
    .then((response) => {
      if (response.data.success === true) {
      }
      return Promise.resolve(response.data);
    });
};

const register = (data) => {
  return axios
    .post(API_URL + "register", JSON.stringify(data), {
      headers: {
      'Content-Type': 'application/json',
      'Content-Type': 'text/plain'
      }
    })
    .then((response) => {
      if (response.data.success === true) {
      }
      return Promise.resolve(response.data);
    });
};

const refreshToken = () => {
  return httpRequest.post('shipment/api/shipment_login_api/refreshToken');
}

const get_captcha = () => {
  return axios
    .post(API_URL + "get_captcha")
    .then((response) => {
      if (response.data.success === true) {

      }
      return Promise.resolve(response.data);
    });
}



const exp = {
  login,
  refreshToken,
  register,
  get_captcha
};

export default exp;