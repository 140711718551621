import axios from 'axios'
import { REQUEST_HEADER } from '../config/constants'
import { errorException } from './handler'
import authHeader_token from "../api/auth-header-token";
//import global from '../global';
//import Toastr from "../components/layout/Toastr";

// function refreshToken(user_name, pass_word) {
//   const data = {user_name,pass_word}
//   //return httpRequest.post(global.api_url+'users/api/users_api/refreshToken', JSON.stringify(data)).then((res) => res.data);
//   return httpRequest.post('/users/api/users_api/refreshToken', JSON.stringify(data)).then((res) => res.data);
// }

const API_URL = process.env.REACT_APP_API_ENDPOINT;

const httpRequest = axios.create({
  timeout: 300000,
  baseURL: API_URL,
  headers: REQUEST_HEADER
})

httpRequest.setToken = (token) => {
  localStorage.setItem('token_localStorage', token)
};

const ISSERVER = typeof window === 'undefined';
httpRequest.interceptors.request.use(
  (config) => {
    if (!ISSERVER) {
      const token = authHeader_token.HeaderToken()
      if (token) {
        config.headers = config.headers || {};
        //config.headers.Authorization = `${token}`;
        config.headers.Authorization =  token ? `${token}` : '';
      }
    }
    return config
  },
  (error) => {
    console.log('-----loi-----');
    Promise.reject(error)
  }
)


// let isRefreshing = false;
// let requests = [];

httpRequest.interceptors.response.use(
  (response) => {
    return response;
  },
  async(error) => {
    return errorException(error, httpRequest)
  }
);

export default httpRequest
