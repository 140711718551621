import React, { useState, useEffect } from 'react';

import httpRequest from '../../services/httpRequest';

import axios from "axios";

function Test_auth(props) {
    const [inputValue, setValue] = useState('');
	const url = 'http://api-shipment.sieuthimad247.com/welcome/test'
	const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjEiLCJ2ZXJzaW9uX2FwcCI6IjIuNS4yIiwicmVzdWx0Ijp7InVzZXJfbmFtZSI6ImFkbWluIiwicGFzc193b3JkIjoiZTEwYWRjMzk0OWJhNTlhYmJlNTZlMDU3ZjIwZjg4M2UiLCJobyI6IkFkbWluIiwidGVuIjoiQWRtaW4iLCJpZCI6IjEiLCJsZXYiOiIxIiwiaW1hZ2UiOiJodHRwOlwvXC9hcGktc2hpcG1lbnQuc2lldXRoaW1hZDI0Ny5jb21cL3VwbG9hZHNcL3VzZXJzXC9pbWFnZXNcLzE2MzIxMzMyNTkucG5nIiwibW9kaWQiOiIyLDMiLCJpc19tb2R1bGVfbmFtZSI6InVzZXJzIiwiYWN0aW9uX21vaWQiOm51bGwsImVkaXRvciI6IjEiLCJraG9faWQiOiIxIiwicXQiOiIxIiwiYmgiOiIxIiwiaXNfbG9nX3dlYiI6dHJ1ZSwiaXNfbG9nZ2VkX2luIjp0cnVlLCJsZXZfcXQiOnRydWUsImN1c19sb2ciOmZhbHNlfSwiQVBJX1RJTUUiOjE2NjY2ODM1MTN9.s1sGN_8HvwSxRB2T2pSH2FyT7cXzmzHijBvRJLTSy1k';
	const getdata = () => {
		var config = {
			method: 'POST',
			url: 'http://api-shipment.sieuthimad247.com/welcome/test1',
			//url: 'http://api-pos.nitsoftvn.com/test_send_api.php',
			//url: 'http://api-shipment.vantaitoanquoc4t.com/welcome/test1',
			//url: 'http://api-shipment.vantaitoanquoc4t.com/orders/api/orders_api/ajax_list',
			//url: 'http://127.0.0.1/ci3_soft_vanchuyen/welcome/test1',
			headers: { 
				"Authorization" : `${token}`
			}
		};
		  
		  axios(config)
		  .then(function (response) {
			console.log('success '+ JSON.stringify(response.data));
		  })
		  .catch(function (error) {
			console.log(error);
		  });

		// httpRequest.post('http://127.0.0.1/ci3_soft_vanchuyen/orders/api/orders_api/ajax_list?mahoadon=&khachhang=&productid=&tensp=&datetimefrom=Invalid%20date&datetimeto=Invalid%20date&admin_view=isViewAdmin').then(response => {
		// 	console.log(JSON.stringify(response));
		// });

		// var myHeaders = new Headers();
		// myHeaders.append("Authorization", "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjEiLCJ2ZXJzaW9uX2FwcCI6IjIuNS4yIiwicmVzdWx0Ijp7InVzZXJfbmFtZSI6ImFkbWluIiwicGFzc193b3JkIjoiZTEwYWRjMzk0OWJhNTlhYmJlNTZlMDU3ZjIwZjg4M2UiLCJobyI6IkFkbWluIiwidGVuIjoiQWRtaW4iLCJpZCI6IjEiLCJsZXYiOiIxIiwiaW1hZ2UiOiJodHRwOlwvXC9hcGktc2hpcG1lbnQuc2lldXRoaW1hZDI0Ny5jb21cL3VwbG9hZHNcL3VzZXJzXC9pbWFnZXNcLzE2MzIxMzMyNTkucG5nIiwibW9kaWQiOiIyLDMiLCJpc19tb2R1bGVfbmFtZSI6InVzZXJzIiwiYWN0aW9uX21vaWQiOm51bGwsImVkaXRvciI6IjEiLCJraG9faWQiOiIxIiwicXQiOiIxIiwiYmgiOiIxIiwiaXNfbG9nX3dlYiI6dHJ1ZSwiaXNfbG9nZ2VkX2luIjp0cnVlLCJsZXZfcXQiOnRydWUsImN1c19sb2ciOmZhbHNlfSwiQVBJX1RJTUUiOjE2NjY2ODM1MTN9.s1sGN_8HvwSxRB2T2pSH2FyT7cXzmzHijBvRJLTSy1k");
		// myHeaders.append("Cookie", "ci_cookie=hmgmd4rdpqb5e3hmsngkc6itj5jsm6s0");

		// var requestOptions = {
		// method: 'POST',
		// headers: myHeaders,
		// redirect: 'follow'
		// };

		// fetch("http://api-shipment.sieuthimad247.com/welcome/test1", requestOptions)
		// .then(response => response.text())
		// .then(result => console.log(result))
		// .catch(error => console.log('error', error));
	}

	const getdata_localhost = () => {
		var config = {
			method: 'POST',
			url: 'http://127.0.0.1/ci3_soft_vanchuyen/welcome/test1',
			headers: { 
				"Authorization" : `${token}`
			}
		};
		  
		  axios(config)
		  .then(function (response) {
			console.log('success '+ JSON.stringify(response.data));
		  })
		  .catch(function (error) {
			console.log(error);
		  });
	}

	

    return (
        <>ng
		<br />
		<button className='btn btn-success' onClick={getdata}>Send</button>
		<button className='btn btn-success' onClick={getdata_localhost}>Send localhost</button>
		</>
    );
}



export default Test_auth;
