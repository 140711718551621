import React, { useState, useRef, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import AuthHelperMethods from '../../helpers/AuthHelperMethods';
import System_lib from '../../libs/System_lib';

import InternacionalizationMenu from "../ChangeLanguage/InternacionalizationMenu";
import packageJson from "../../../package.json";

const Header = (props) => {
    const Auth = new AuthHelperMethods();
    const system_lib = new System_lib();

    const [showDropDown, setshowDropDown] = useState(false);

    useEffect(() => {
        // if (typeof window !== "undefined") {
        //     if(us){
        //         const version_app_local = localStorage.getItem('version_app')
        //         const version_app = (version_app_local === null)? 1: parseFloat(version_app_local)
        //         if(parseFloat(us.version_app) > version_app){
        //             //system_lib.emptyCache();
        //             //localStorage.setItem('version_app', parseFloat(us.version_app))
        //             if(system_lib.emptyCache() === 1){
        //                 localStorage.setItem('version_app', parseFloat(us.version_app))
        //             }
        //         }
        //     }
        // }
        caching()
    }, [])

    const reload_page = () => {
        window.location.reload()
    }
    const us = Auth.getConfirm();

    const showDropdown = () => {
        setshowDropDown(!showDropDown)
    }
    const className_liDropDown = showDropDown ? 'nav-item nav-profile dropdown show' : 'nav-item nav-profile dropdown';
    const dropdown_menu = showDropDown ? 'dropdown-menu show' : 'dropdown-menu';
    const aria_expanded = showDropDown ? true : false

    const ref_infoUser = useRef();

    useOnClickOutside(ref_infoUser, () => setshowDropDown(false));

    function useOnClickOutside(ref, handler) {
        useEffect(
            () => {
                const listener = (event) => {
                    if (!ref.current || ref.current.contains(event.target)) {
                        return;
                    }
                    handler(event);
                };
                document.addEventListener("mousedown", listener);
                document.addEventListener("touchstart", listener);
                return () => {
                    document.removeEventListener("mousedown", listener);
                    document.removeEventListener("touchstart", listener);
                };
            },
            [ref, handler]
        );
    }

    //console.log(process.env.REACT_APP_API_ENDPOINT);

    const caching = () => {
        let version = localStorage.getItem('version');
        if (version != packageJson.version) {
            if ('caches' in window) {
                caches.keys().then((names) => {
                    //console.log(names);
                    // Delete all the cache files
                    names.forEach(name => {
                        caches.delete(name);
                    })
                });

                // Makes sure the page reloads. Changes are only visible after you refresh.
                window.location.reload(true);
            }

            localStorage.clear();
            localStorage.setItem('version', packageJson.version);
        }
    };

    return (
        <>
            <div className="page-header">
                <nav className="navbar navbar-expand">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <ul className="navbar-nav">
                        <li className="nav-item small-screens-sidebar-link">
                            <a href="#" className="nav-link" onClick={props.Toggle_sidebar_mobile}><i className="material-icons-outlined">menu</i></a>
                        </li>

                        <li className={className_liDropDown} onClick={showDropdown}>
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded={aria_expanded}>
                                <img src={(us !== null) ? us.result.image : ''} alt="profile image" />
                                <span>{(us !== null) ? us.result.ho + ' ' + us.result.ten : ''}</span><i className="material-icons dropdown-icon">keyboard_arrow_down</i>
                            </a>
                            <div className={dropdown_menu} aria-labelledby="navbarDropdown" ref={ref_infoUser}>
                                <Link className="dropdown-item" to="/admin/users/info">Đổi thông tin</Link>
                                <div className="dropdown-divider"></div>
                                <Link className="dropdown-item" to="/admin/system_update">Cập nhật hệ thống</Link>
                                <div className="dropdown-divider"></div>
                                <Link className="dropdown-item" to="/admin" onClick={props.logout}>Đăng xuất</Link>
                            </div>
                        </li>
                        <li className="nav-item">
                            <a onClick={reload_page} href="#" className="nav-link"><i className="material-icons-outlined">refresh</i> Tải lại trang</a>
                        </li>
                    </ul>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            {(us !== null) && parseInt(us.result.lev) === 1 ?
                                <>
                                    <li className="nav-item">
                                        <Link to="/admin/backup" className="nav-link">Sao lưu dữ liệu</Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link to="/system" className="nav-link">Hệ thống</Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link to="/routes" className="nav-link">Định tuyến</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/media_main" className="nav-link">Đa phương tiện</Link>
                                    </li>
                                    

                                </>

                                : ''}
                        </ul>
                    </div>
                </nav>
            </div>
        </>
    )
}



export default Header;