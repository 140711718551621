import axios from "axios";

import httpRequest from '../../services/httpRequest';

// import global from '../../global';
// const API_URL = global.api_url + 'users/api/users_api/';
const API_URL = process.env.REACT_APP_API_ENDPOINT + 'users/api/users_api/';


const login = (username, password) => {
  return axios
    .post(API_URL + "login", {
      username,
      password,
    }, {
      headers: {
      'Content-Type': 'application/json',
      'Content-Type': 'text/plain'
      }
    })
    .then((response) => {
      if (response.data.success === '1') {
        //console.log(response.data.result.token);
        //localStorage.setItem("token_localStorage", JSON.stringify(response.data.result.token));
        //authHeader_token.setToken(JSON.stringify(response.data.result.token));
        //localStorage.setItem("user_localStorage", JSON.stringify(response.data));
        //window.sessionStorage.setItem("token_localStorage", JSON.stringify(response.data.result.token)); //bị mất khi đóng trình duyệt
      }
      //return response.data;
      return Promise.resolve(response.data);
    });

    //const data = {username, password}
    //return httpRequest.post(API_URL + "login", (data));

};

const refreshToken = () => {
  return httpRequest.post('users/api/users_api/refreshToken');
}

//get token o refreshToken
function getLocalRefreshToken() {
  const token = window.localStorage.getItem('refreshToken')
  return token
}

function refreshToken1 () {
  return httpRequest.post('/token',{
      refreshToken: getLocalRefreshToken()
  })
}
//end refreshToken

const getUserBoard = () => {
  return httpRequest.get(API_URL + "lists");
};

const actionfind = (id) => {
  let config = {
    params: {
      id: id
    },
  }
  return httpRequest.get(API_URL + "action", config);
};

const process_action = (data) => {
  return httpRequest.post(API_URL + "process_action", (data));
};

const getPublicContent = () => {
  //return axios.get(API_URL + "all");
  return httpRequest.get(API_URL + "all");
};

const getModeratorBoard = () => {
  //return axios.get(API_URL + "mod", { headers: authHeader() });
  return httpRequest.get(API_URL + "mod");
};

const getAdminBoard = () => {
  //return axios.get(API_URL + "admin", { headers: authHeader() });
  return httpRequest.get(API_URL + "admin");
};

const del = (id) => {
  return httpRequest.delete(API_URL + "delete_data/" + id);
};

const getInfo = () => {
  return httpRequest.get(API_URL + "change_info");
};

const ChangeInfo = (data) => {
  return httpRequest.post(API_URL + "change_info", (data));
};


const list_kho = () => {
  return httpRequest.get(API_URL + "list_kho");
};


const exp = {
  login,
  refreshToken,
  getPublicContent,
  getUserBoard,
  actionfind,
  process_action,
  getModeratorBoard,
  getAdminBoard,
  del,
  getInfo,
  ChangeInfo,
  list_kho
};
export default exp;